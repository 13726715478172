/* eslint-disable react-hooks/exhaustive-deps */
import './scss/style.scss';
import { verifyUser } from './utils/OAuth';
import { Route, Routes, useNavigate } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import '@coreui/coreui/dist/css/coreui.min.css';
import '@coreui/coreui-pro/dist/css/coreui.min.css';

const queryClient = new QueryClient();

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse" />
    </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Recovery = React.lazy(() => import('./views/pages/RecoverPassword/RecoverPassword'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
    const navigate = useNavigate();
    const [userOptions, setUserOptions] = useState({ options: {}, userRole: '' });
    const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);

    useEffect(() => {
        if (!['recover', 'password'].some((s) => window.location.href.includes(s))) {
            if (!isAuthenticated()) {
                navigate('/login');
            }
        }
    }, []);

    useEffect(() => {
        if (userOptions.options) {
            const nextPath = window.location.href.split('/')[window.location.href.split('/').length - 1];
            if (Object.keys(userOptions.options).length) {
                userOptions[nextPath] ? navigate(`/${nextPath}`) : navigate(`/`);
            }
        }
    }, [userOptions]);

    const isAuthenticated = async () => {
        const token = localStorage.getItem('encodedToken');
        if (token && token !== null) {
            await verifyUser().then((response) => {
                if (response?.code !== 401) {
                    setUserOptions({
                        options: response?.user?.options?.views ? response?.user?.options?.views : {},
                        userRole: response?.user?.role ? response?.user?.role : 'user',
                    });
                    setUserIsAuthenticated(true);
                    return true;
                }
                logoutUser();
            });
        } else {
            setUserIsAuthenticated(false);
            navigate('/login');
            return false;
        }
    };

    const logoutUser = () => {
        localStorage.clear();
        setUserIsAuthenticated(false);
        navigate('login');
    };

    return (
        <QueryClientProvider client={queryClient}>
            <Suspense fallback={loading}>
                <Routes>
                    {!userIsAuthenticated && (
                        <Route
                            exact
                            path="/login"
                            name="Login Page"
                            element={<Login isAuthenticated={isAuthenticated} />}
                        />
                    )}
                    {userOptions && (
                        <Route
                            path="*"
                            name="Home"
                            element={
                                <DefaultLayout
                                    logoutUser={logoutUser}
                                    isAuthenticated={isAuthenticated}
                                    userIsAuthenticated={userIsAuthenticated}
                                    userOptions={userOptions.options}
                                    userRole={userOptions.userRole}
                                />
                            }
                        />
                    )}
                    <Route exact path="/password/:id" name="Password Page" element={<Recovery />} />
                    <Route exact path="/recover/:id" name="Recovery Page" element={<Recovery />} />
                    <Route exact path="/register" name="Register Page" element={<Register />} />
                    <Route exact path="/404" name="Page 404" element={<Page404 />} />
                    <Route exact path="/500" name="Page 500" element={<Page500 />} />
                </Routes>
            </Suspense>
        </QueryClientProvider>
    );
};

export default App;
