import { tokenKey } from './OAuth';

export const baseApiUrl = process.env.REACT_APP_API_URL;
export const appTitle = 'FoodFinder';
export const geoCodeApi = 'AIzaSyCAjI1JoKPN2Bkjw7ySZxsPyInYWkghEbo';

export const getHttpHeaders = (method) => {
    let init = {
        method,
        headers: {
            Accept: '*/*',
            'Content-type': 'application/json',
        },
    };
    let token = localStorage.getItem(tokenKey);
    if (token) {
        init.headers.Authorization = `Bearer ${token}`;
    }
    return init;
};

export const httpGetRequest = async (callbackMethod) => {
    let response = await callbackMethod();
    return response.json();
};

export const httpPostRequest = async (callbackMethod) => {
    let response = await callbackMethod();
    return response.json();
};

export const getHttpHeadersFile = (method) => {
    let init = {
        method,
        headers: {
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
    };
    let token = localStorage.getItem(tokenKey);
    if (token) {
        init.headers.Authorization = `Bearer ${token}`;
    }
    return init;
};

export const getHttpHeadersExcelImport = (method) => {
    let init = {
        method,
        headers: {
            Accept: '*/*',
        },
    };
    let token = localStorage.getItem(tokenKey);
    if (token) {
        init.headers.Authorization = `Bearer ${token}`;
    }
    return init;
};

export const httpGetRequestFile = async (callbackMethod) => {
    let response = await callbackMethod();
    return response;
};
