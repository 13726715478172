import { baseApiUrl, getHttpHeaders, httpGetRequest, httpPostRequest } from './ApiUtils';

export const tokenKey = 'encodedToken';

export const loginUser = (credentials) => {
    return httpPostRequest(loginUserInfo.bind(this, credentials));
};

const loginUserInfo = async (credentials) => {
    let init = getHttpHeaders('POST');
    init.body = JSON.stringify(credentials);
    let response = await fetch(`${baseApiUrl}/admin/auth`, init);
    return response;
};

export const verifyUser = () => {
    return httpPostRequest(verifyUserInfo.bind(this));
};

const verifyUserInfo = async () => {
    let init = getHttpHeaders('GET');
    let response = await fetch(`${baseApiUrl}/admin/auth/verify`, init);
    return response;
};

export const recoverUser = (credentials) => {
    return httpPostRequest(recoverUserInfo.bind(this, credentials));
};

const recoverUserInfo = async (credentials) => {
    let init = getHttpHeaders('POST');
    init.body = JSON.stringify(credentials);
    let response = await fetch(`${baseApiUrl}/recover`, init);
    return response;
};

export const resetPassword = (credentials) => {
    return httpPostRequest(resetPasswordInfo.bind(this, credentials));
};

const resetPasswordInfo = async (credentials) => {
    let init = getHttpHeaders('POST');
    init.body = JSON.stringify(credentials);
    let response = await fetch(`${baseApiUrl}/recover_password`, init);
    return response;
};

export const verifyToken = (token) => {
    return httpGetRequest(verifyTokenInfo.bind(this, token));
};

const verifyTokenInfo = async (token) => {
    let init = getHttpHeaders('GET');
    let response = await fetch(`${baseApiUrl}/verify_token/${token}`, init);
    return response;
};
